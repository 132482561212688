<template>
    <div>
        <van-empty description="暂无数据" v-if="grid.ls.length == 0" />
        <div v-else>
            <van-list :finished="grid.finish" @load="loadPage" v-model="grid.loading" :immediate-check="false"
                style="padding: 10px 0px">
                <div class="wcard" @click="edit(item)" v-for="item in grid.ls" :key="item.ID">
                    <div class="bd">
                        <van-row :gutter="8">
                            <van-col :span="24">
                                <p style="display: flex;">
                                    荣誉名称：{{ item.NAME }}
                                    <span style="margin-left: auto;color: red;font-size: 18px;"><van-icon name="delete"
                                            @click.stop="del(item)" /></span>
                                </p>
                                <p style="font-size: 14px; color: #999">
                                    荣获日期：{{ item.OBTAIN_DT | datetime("YYYY-MM-DD") }}
                                </p>
                                <p><van-image v-for="(item, index) in item.IMAGELIST" :key="index" :src="item.Value"
                                        style="width: 50px;height: 50px;  margin-top: 8px;margin-right: 5px"
                                        fit="cover" />

                                </p>
                            </van-col>
                        </van-row>
                    </div>
                    <div class="ft">
                        <van-row>
                            <!--              <van-col :span="8" :class="item.STA==10?'':'ov'">-->
                            <!--                状态：{{item.STA_TXT}}-->
                            <!--              </van-col>-->
                            <van-col :span="12">创建老师：{{ item.TEACHER_NAME }}</van-col>
                            <van-col :span="12" style="text-align: right">创建日期：{{
            item.CREATED_DT | datetime("YYYY-MM-DD")
                                }}</van-col>
                        </van-row>
                    </div>
                </div>
            </van-list>
        </div>
    </div>
</template>
<script>
export default {
    name: "index",
    data() {
        return {
            grid: {
                sea: {
                    PAGE_SIZE: 10,
                    PAGE_INDEX: -1,
                },
                ls: [],
                total: 0,
                loading: false,
                finish: false,
            },
        };
    },
    mounted() {
        if (this.$route.query.for == "SUB") {
            document.title = "我的记录";
        }
    },
    created() {
        this.filter();
    },
    methods: {
        filter() {
            this.grid.sea.PAGE_INDEX = 0;
            this.grid.ls = [];
            this.grid.finish = false;
            this.getList();
        },
        loadPage() {
            this.grid.sea.PAGE_INDEX += 1;
            this.getList();
        },
        getList() {
            let self = this;
            this.grid.loading = true;
            this.whale.remote.getCollection({
                url: "/api/Mobile/ORG/TeaHonorApi/GetList",
                data: this.grid.sea,
                finally() {
                    self.grid.loading = false;
                },
                completed: function (its, n) {
                    self.grid.ls.push(...its.ITEMS);
                    if (self.grid.ls.length >= n) self.grid.finish = true;
                },
            });
        },
        edit(item) {
            this.$router.push({ path: "/org/teahonor/edit", query: { id: item.ID } });
        },
        del(item) {
            console.log('del:', item.ID)
            this.$dialog.confirm({
                message: '你确定要删除吗?',
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.whale.remote.getResult({
                    url: "/api/Mobile/ORG/TeaHonorApi/Delete",
                    data: { id: item.ID },
                    completed: () => {
                        this.filter();
                    }
                })
            }).catch(() => {
                // on cancel
            });
        }
    },
};
</script>

<style scoped>
.wcard {
    margin: 0 12px 12px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
}

.wcard .ft {
    border-top: 1px dashed #ebedf0;
    padding: 8px 16px;
    font-size: 12px;
    min-height: 20px;
}

.wcard .ft .ov {
    color: #ee0a24;
    font-weight: bold;
}

.wcard .bd {
    padding: 0px 15px;
}

.wcard .bd .lf {
    text-align: center;
    color: #ee0a24;
    padding-top: 10px;
}

.wcard .bd .lf h2 {
    font-size: 30px;
    font-weight: 500;
    display: inline;
}

.wcard .bd .lf span {
    font-size: 40%;
    font-weight: normal;
    margin-left: 2px;
}

.wcard .bd .lf p {
    font-size: 14px;
    line-height: 16px;
}
</style>